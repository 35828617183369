.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  font-family: helvetica;
  font-size: 100px;
  font-weight: bold;
  color: rgba(255, 0, 0, 0.877);
  background-color: #111111;
  cursor: default;
}


